import { Link } from "gatsby"
import React from "react"

import "./footer.scss"

const Footer = () => (
  <footer className="footer">
    <div className="center -all-width">

      <div className="footer__top">

        <div className="center">
        
          <div className="footer__top__columns">
            
            <div className="footer__top__column">
              <h6 className="footer__top__column__title"><span>Legal</span></h6>
              <div className="footer__top__column__content">
                <div className="footer__top__column__content__column">
                  <Link to="/terms-of-services/" className="footer__top__column__content__column__link">Terms of Service</Link>
                  <br/>
                  <Link to="/privacy-policy/" className="footer__top__column__content__column__link">Privacy Policy</Link>
                </div>
                <div className="footer__top__column__content__column">
                  <Link to="/end-user-license-agreement/" className="footer__top__column__content__column__link">EULA</Link>
                  <br/>
                  <Link to="/cookie-policy/" className="footer__top__column__content__column__link">Cookie Policy</Link>
                </div>
              </div>
            </div>
            
            <div className="footer__top__column">
              <h6 className="footer__top__column__title"><span>Social Media</span></h6>
              <div className="footer__top__column__content -no-side-margins">
                <div className="footer__top__column__content__column -nowrap">
                  <a href="https://www.linkedin.com/company/super-dot-com/" className="footer__top__column__content__column__social-link -linkedin" target="_blank" rel="noopener noreferrer">linkedin</a>
                  <a href="https://www.facebook.com/supercomltd" className="footer__top__column__content__column__social-link -facebook" target="_blank" rel="noopener noreferrer">facebook</a>
                  <a href="https://twitter.com/supercomltd" className="footer__top__column__content__column__social-link -twitter" target="_blank" rel="noopener noreferrer">twitter</a>
                  <a href="https://www.instagram.com/supercomltd/" className="footer__top__column__content__column__social-link -instagram" target="_blank" rel="noopener noreferrer">instagram</a>
                  <a href="https://www.youtube.com/channel/UC6UuWGRr4MXR6zH8wsxNGGQ" className="footer__top__column__content__column__social-link -youtube" target="_blank" rel="noopener noreferrer">youtube</a>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div className="footer__bottom">

        <div className="center">

          <div className="footer__bottom__columns">
          
            <div className="footer__bottom__column">
              <h6 className="footer__bottom__column__title">General inquiries</h6>
              <div className="footer__bottom__column__item">
                <span className="footer__bottom__column__item__label">Email:</span>
                <a href="mailto:info@supergg.com" className="footer__bottom__column__item__link">info@supergg.com</a>
              </div>
            </div>
            
            {/* <div className="footer__bottom__column">
              <h6 className="footer__bottom__column__title">Press</h6>
              <div className="footer__bottom__column__item">
                <span className="footer__bottom__column__item__label">Email:</span>
                <a href="mailto:marketing@supergg.com" className="footer__bottom__column__item__link">marketing@supergg.com</a>
              </div>
              <div className="footer__bottom__column__item">
                <span className="footer__bottom__column__item__label">Phone:</span>
                <a href="tel:+447482877158" className="footer__bottom__column__item__link">+44 (748) 287 7158</a>
              </div>
            </div> */}
            
            <div className="footer__bottom__column">
              <h6 className="footer__bottom__column__title">HQ address</h6>
              <div className="footer__bottom__column__item">
                HQ address: 156A Burnt Oak Broadway, 
              </div>
              <div className="footer__bottom__column__item">
                Edgware, Middlesex, HA8 0AX, United Kingdom
              </div>
            </div>
            
            <div className="footer__bottom__column">
              <h6 className="footer__bottom__column__title">Legal name</h6>
              <div className="footer__bottom__column__item -shifted">
                SUPER DOT COM LTD
              </div>
            </div>

          </div>

          <Link to="/" className="footer__bottom__logo">Supergg.com</Link>

        </div>

      </div>

    </div>
  </footer>
)

export default Footer
