import React from "react"

import "./form.scss"

const Form = () => (

  <>

    <div className="form -step-1">
      
      <form className="form__wrapper">

        <h6 className="game-section__title__text form__title">Submit your <span className="game-section__title__text__accent">project</span></h6>

        <div className="form__cross"></div>

        <div className="form__step -first -step-1">
          <p className="form__step__first-message">
            The Super journey starts here. The next few steps <br/>
            will help us get to know your project better.
          </p>
          <div className="form__step__title">
            <span>1/2</span>
            <b>Personal Data</b>
          </div>
          <div className="form__step__content">
            <div className="form__step__grid">
              <div className="form__step__grid__line -tripple">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="first-name" data-required="true"/>
                    <label className="ui-input__label">First Name</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="last-name" data-required="true"/>
                    <label className="ui-input__label">Last Name</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="e-mail" data-required="true"/>
                    <label className="ui-input__label">Email</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
              </div>
              <div className="form__step__grid__line -tripple">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="company-name" data-required="true"/>
                    <label className="ui-input__label">Company Name</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="number" className="ui-input__field" min="1" name="team-size" data-required="true"/>
                    <label className="ui-input__label">Team Size</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="project-country" data-required="true"/>
                    <label className="ui-input__label">Country</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
              </div>
              <div className="form__step__grid__line -alone">
                <div className="form__step__grid__column -to-center">
                  <div className="ui-checkbox -small-text">
                    <input type="checkbox" className="ui-checkbox__input" name="terms-agreement" data-required="true"/>
                    <label className="ui-checkbox__label">I agree that my personal data may be transferred and processed according to the</label>
                    &nbsp;
                    <a href="/terms-of-services/" className="ui-checkbox__link" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form__step__button">
            <div className="form__step__button__link -disabled" data-to="2">
              <span className="form__step__button__link__text">Next Step</span>
            </div>
            <div className="form__step__button__shadow"></div>
          </div>
        </div>

        <div className="form__step -step-2">
          <div className="form__step__title">
            <span>2/2</span>
            <b>about your project</b>
          </div>
          <div className="form__step__content">
            <div className="form__step__grid">
              
              <div className="form__step__grid__line">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="project-name" data-required="true"/>
                    <label className="ui-input__label">Project name</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="detailed-breakdown-of-your-budget"/>
                    <label className="ui-input__label">Detailed budget breakdown (link)</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
              </div>
              
              <div className="form__step__grid__line">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="gameplay-video"/>
                    <label className="ui-input__label">Gameplay video (link)</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="pitch-deck"/>
                    <label className="ui-input__label">Pitch deck (link)</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
              </div>
              
              <div className="form__step__grid__line">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="game-design-documentation"/>
                    <label className="ui-input__label">Game design documentation (link)</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="cvs-of-your-team-members"/>
                    <label className="ui-input__label">CVs of your team members (link)</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
              </div>
              
              <div className="form__step__grid__line">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="playable-demo"/>
                    <label className="ui-input__label">Playable demo (link)</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
                <div className="form__step__grid__column">
                  <label className="form__step__label">Services that interest you</label>
                  <div className="ui-checkbox -inline">
                    <input type="checkbox" className="ui-checkbox__input" name="services-that-interest-you__publishing"/>
                    <label className="ui-checkbox__label">Publishing</label>
                  </div>
                  <div className="ui-checkbox -inline">
                    <input type="checkbox" className="ui-checkbox__input" name="services-that-interest-you__funding"/>
                    <label className="ui-checkbox__label">Funding</label>
                  </div>
                </div>
              </div>
              
              <div className="form__step__grid__line -alone">
                <div className="form__step__grid__column">
                  <div className="ui-input">
                    <input type="text" className="ui-input__field" name="projects-idea"/>
                    <label className="ui-input__label">Project’s idea</label>
                    <span className="ui-input__focus-indicator"></span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div className="form__step__button">
            <div className="form__step__button__link -disabled" data-to="3">
              <span className="form__step__button__link__text">Submit</span>
            </div>
            <div className="form__step__button__shadow"></div>
          </div>
        </div>

        <div className="form__step -step-3">
          <p className="form__step__last-message"><span>Successfully sent!</span></p>
          <div className="form__step__content -success">
            We look forward to having the opportunity to collaborate with you and make your product a success. We'll review the product and get back to you soon.
          </div>
          {/* <div className="form__persons-grid">
            <div className="form__person">
              <div className="form__person__photo -jakub"></div>
              <div className="form__person__text">
                <div className="form__person__text__name">Jakub Trudzik</div>
                <div className="form__person__text__position">SUPER BD Manager</div>
                <hr className="form__person__text__line"/>
                <a href="mailto:jakub.trudzik@supergg.com" className="form__person__text__link">jakub.trudzik@supergg.com</a>
              </div>
            </div>
            <div className="form__person">
              <div className="form__person__photo -alexey"></div>
              <div className="form__person__text">
                <div className="form__person__text__name">Alexey Galizdra</div>
                <div className="form__person__text__position">Fund Manager</div>
                <hr className="form__person__text__line"/>
                <a href="mailto:alexey.galizdra@supergg.com" className="form__person__text__link">alexey.galizdra@supergg.com</a>
              </div>
            </div>
          </div> */}
        </div>

      </form>
      
    </div>

  </>

)

export default Form
