import React from "react"

import "./loader.scss"
import main_video_cover from "../../../videos/main-cover.jpg"
import games_video_cover from "../../../videos/games-cover.jpg"
import publ_video_cover from "../../../videos/publishing-cover.jpg"
import fund_video_cover from "../../../videos/fund-cover.jpg"

const Loader = ({ location }) => {
  
  const current_location = typeof window !== 'undefined' ? window.location.pathname.replace(/\/$/,'') : '';
  
  const is_home_page = current_location === '';
  const is_games_page = current_location === '/games';
  const is_publ_page = current_location === '/publishing';
  const is_fund_page = current_location === '/fund';

  return(
    <>
      {
        is_home_page
        ?
          <img src={main_video_cover} className="preload-image" alt="Home"/>

        : is_games_page
        ?
          <img src={games_video_cover} className="preload-image" alt="Games"/>

        : is_publ_page
        ?
          <img src={publ_video_cover} className="preload-image" alt="Publishing"/>

        : is_fund_page
        ?
          <img src={fund_video_cover} className="preload-image" alt="Fund"/>

        :
          <img src={main_video_cover} className="preload-image" alt="Home"/>

      }
      <div className="loader -main">
        <div className="loader-main">
          <div className="loader-main__text">
            <div className="loader-main__text__item">SUPER</div>
            <div className="loader-main__text__item">POWERS</div>
            <div className="loader-main__text__item">FOR</div>
            <div className="loader-main__text__item">INFINITE</div>
            <div className="loader-main__text__item">POSSIBILITIES</div>
          </div>
        </div>
      </div>
      <div className="loader -cross-page">
        <div className="loader-cross-page">
          <div className="loader-cross-page__progress"></div>
          <div className="loader-cross-page__mask"></div>
        </div>
      </div>
    </>
  )

}

export default Loader
