import { Link } from "gatsby"
import React from "react"
import CustomScroll from "react-custom-scroll";

import "./navigation.scss"

const Navigation = () => {
  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  const gamesPage = path.includes('/games') ? '-show' : '';
  const gamesPageParentClass = path.includes('/games') ? '-active' : '';

  return (
    <>
      <button className="sound-trigger -muted">Sound turn off/on</button>

      <nav className="navigation">
        <button className="navigation__trigger">Navigation</button>
        <div className="navigation__content">
          <CustomScroll heightRelativeToParent="100%">
            <div className="navigation__content__inner">
              <Link to="/" className="navigation__content__item" activeClassName="-active">Home</Link>
              <Link to="/games/" className={'navigation__content__item ' + gamesPageParentClass} activeClassName="-active">Our games</Link>
                <Link to="/games/deadlink/" className={'navigation__content__item -sub -margin-top ' + gamesPage} activeClassName="-active">Deadlink</Link>
                <Link to="/games/raji/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Raji: an Ancient Epic</Link>
                <Link to="/games/deflector/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Deflector</Link>
                <Link to="/games/zelter/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Zelter</Link>
                <Link to="/games/alchemist-adventure/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Alchemist Adventure</Link>
                <Link to="/games/metaphora/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Metaphora the Moonycat Quest</Link>
                <Link to="/games/retro-machina/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Retro Machina</Link>
                <Link to="/games/broken-lines/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Broken Lines</Link>
                <Link to="/games/wonhon/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Wonhon: a Vengeful Spirit</Link>
                <Link to="/games/tiltpack/" className={'navigation__content__item -sub ' + gamesPage} activeClassName="-active">Tilt Pack</Link>
              <Link to="/publishing/" className="navigation__content__item" activeClassName="-active">Publishing</Link>
              {/* <Link to="/fund/" className="navigation__content__item" activeClassName="-active">Funding</Link> */}
              <Link to="/news/" className="navigation__content__item" activeClassName="-active">News</Link>
              {/* <Link to="/team/" className="navigation__content__item" activeClassName="-active">Team</Link>
              <Link to="/press/" className="navigation__content__item" activeClassName="-active">Press</Link> */}
              <a href="https://forms.gle/6p3psuHNnWNVnNia6" className="navigation__content__item" target="_blank" rel="noopener noreferrer">Contact Us</a>
            </div>
          </CustomScroll>
        </div>
      </nav>
    </>
  )
}

export default Navigation
