import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import default_thumbnail from "../../../images/icons/favicon/apple-touch-icon-512x512.png"

export default ({ SEOData={} }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    }
    render={({
      site:{
        siteMetadata:{
          title,
          description
        }
      }
    }) => {

      const default_seo_title = title;
      const default_seo_description = description;
      const default_seo_page_thumbnail = {
        url: default_thumbnail,
        dimensions: {
          width: 512,
          height: 512
        }
      }
      
      return <Helmet>

        <title>
          { ( SEOData.seo_title ) ? SEOData.seo_title : default_seo_title }
        </title>

        <meta name="description" content=
          { ( SEOData.seo_description ) ? SEOData.seo_description : default_seo_description }
        />

        <meta name="og:title" content=
          { ( SEOData.seo_title ) ? SEOData.seo_title : default_seo_title }
        />
        <meta name="og:type" content="website"/>
        <meta name="og:description" content=
          { ( SEOData.seo_description ) ? SEOData.seo_description : default_seo_description }
        />
        <meta name="og:image" content=
          { ( SEOData.seo_page_thumbnail && SEOData.seo_page_thumbnail.url ) ? SEOData.seo_page_thumbnail.url : default_seo_page_thumbnail.url }
        />
        <meta name="og:image:width" content=
          { ( SEOData.seo_page_thumbnail && SEOData.seo_page_thumbnail.url ) ? SEOData.seo_page_thumbnail.dimensions.width : default_seo_page_thumbnail.dimensions.width }
        />
        <meta name="og:image:height" content=
          { ( SEOData.seo_page_thumbnail && SEOData.seo_page_thumbnail.url ) ? SEOData.seo_page_thumbnail.dimensions.height : default_seo_page_thumbnail.dimensions.height }
        />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content=
          { ( SEOData.seo_title ) ? SEOData.seo_title : default_seo_title }
        />
        <meta name="twitter:description" content=
          { ( SEOData.seo_description ) ? SEOData.seo_description : default_seo_description }
        />
        <meta name="twitter:image" content=
          { ( SEOData.seo_page_thumbnail && SEOData.seo_page_thumbnail.url ) ? SEOData.seo_page_thumbnail.url : default_seo_page_thumbnail.url }
        />

        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>

      </Helmet>

    }}
    />
)