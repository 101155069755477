import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import SEO from "../components/shared/seo/seo"

import Loader from "./shared/loader/loader"
import Navigation from "./shared/navigation/navigation"
import Footer from "./shared/footer/footer"
import Form from "./shared/form/form"
import ImageZoom from "./shared/image-zoom/image-zoom"
import "./layout.scss"

import favicon from "../images/icons/favicon/favicon.png"
import favicon57 from "../images/icons/favicon/apple-touch-icon-57x57.png"
import favicon60 from "../images/icons/favicon/apple-touch-icon-60x60.png"
import favicon72 from "../images/icons/favicon/apple-touch-icon-72x72.png"
import favicon76 from "../images/icons/favicon/apple-touch-icon-76x76.png"
import favicon114 from "../images/icons/favicon/apple-touch-icon-114x114.png"
import favicon120 from "../images/icons/favicon/apple-touch-icon-120x120.png"
import favicon144 from "../images/icons/favicon/apple-touch-icon-144x144.png"
import favicon152 from "../images/icons/favicon/apple-touch-icon-152x152.png"
import favicon180 from "../images/icons/favicon/apple-touch-icon-180x180.png"
import favicon512 from "../images/icons/favicon/apple-touch-icon-512x512.png"

const Layout = ({ SEOData={}, children }) => {

  return (
    <>

      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@800&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap" rel="stylesheet"></link>
        <link rel="icon" type="image/x-icon" href={favicon}/>
        <link rel="apple-touch-icon" sizes="57x57" href={favicon57}/>
        <link rel="apple-touch-icon" sizes="60x60" href={favicon60}/>
        <link rel="apple-touch-icon" sizes="72x72" href={favicon72}/>
        <link rel="apple-touch-icon" sizes="76x76" href={favicon76}/>
        <link rel="apple-touch-icon" sizes="114x114" href={favicon114}/>
        <link rel="apple-touch-icon" sizes="120x120" href={favicon120}/>
        <link rel="apple-touch-icon" sizes="144x144" href={favicon144}/>
        <link rel="apple-touch-icon" sizes="152x152" href={favicon152}/>
        <link rel="apple-touch-icon" sizes="180x180" href={favicon180}/>
        <link rel="apple-touch-icon" sizes="512x512" href={favicon512}/>
      </Helmet>

      <SEO SEOData={SEOData}/>

      <Loader/>

      <div className="app">
        <Navigation/>
        <div className="scroller">
          {children}
          <Footer/>
        </div>
        <Form/>
      </div>

      <ImageZoom/>

    </>
  )
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
