import React from "react"

import "./image-zoom.scss"

const ImageZoom = () => (

  <>

    <div className="image-zoom"></div>

  </>

)

export default ImageZoom
